import React, { Component } from "react";
import {
  Card,
  CardBody,
  CardImg,
  CardText,
  CardTitle,
  Col,
  Row,
} from "reactstrap";

import simple from "../../assets/img/Landing page-slicing/Simple (2).png";
import secure from "../../assets/img/Landing page-slicing/Secure (2).png";
import scalable from "../../assets/img/Landing page-slicing/Scalable (2).png";
import construct from "../../assets/img/Landing page-slicing/Group 70287.png";
import Rwork from "../../assets/img/Landing page-slicing/Group 70288.png";
import Lottie from "react-lottie";
import AdminControl from "../../assets/img/lotties/Admin Control.json";
import EasyOnboarding from "../../assets/img/lotties/Easy Onboarding.json";
import SharedContacts from "../../assets/img/lotties/Shared Contacts.json";
import CreateGroups from "../../assets/img/lotties/Create Groups.json";
import InstantChat from "../../assets/img/lotties/Comp-18.json";
import SendFiles from "../../assets/img/lotties/Send Files.json";
import AnyDevice from "../../assets/img/lotties/Any Device.json";
import WorksGlobally from "../../assets/img/lotties/Works Globally.json";
import SecureEncryption from "../../assets/img/lotties/Secure-Encryption.json";

export default class Benefits extends Component {
  render() {
    return (
      <>
        <div className="benefits bg-white">
          <div className="top mt-0 mb-0 pt-4">
            <h1 className="font-family-2 text-center font-family-3">
              Benefits of Merk Mobile
            </h1>
            <Row xs="2" lg="3">
              <Col className="d-flex flex-column align-items-center">
                <Lottie
                  options={{
                    loop: true,
                    autoplay: true,
                    animationData: AdminControl,
                  }}
                  height={100}
                  width={100}
                  style={{ margin: 0, marginBottom: "1rem" }}
                />

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  // xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="60%"
                  height="8px"
                >
                  <path
                    fill-rule="evenodd"
                    stroke="#201cb1"
                    stroke-width="14.04px"
                    stroke-linecap="butt"
                    stroke-linejoin="miter"
                    fill="#201cb1"
                    d="M7.19,16.19 L7.19,7.19 L259.20,7.19 L259.20,16.19 L7.19,16.19 Z"
                  />
                </svg>
                <h6 className="font-weight-bold mt-3 text-center">
                  Admin Control
                </h6>
                <p className="benefit_subText text-center">
                  The admin can add or remove members at any time. The admin can
                  also send a message to entire team at once.
                </p>
              </Col>
              <Col className="d-flex flex-column align-items-center">
                <Lottie
                  options={{
                    loop: true,
                    autoplay: true,
                    animationData: EasyOnboarding,
                  }}
                  height={100}
                  width={100}
                  style={{ margin: 0, marginBottom: "1rem" }}
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  // xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="60%"
                  height="8px"
                >
                  <path
                    fill-rule="evenodd"
                    stroke="#201cb1"
                    stroke-width="14.04px"
                    stroke-linecap="butt"
                    stroke-linejoin="miter"
                    fill="#201cb1"
                    d="M7.19,16.19 L7.19,7.19 L259.20,7.19 L259.20,16.19 L7.19,16.19 Z"
                  />
                </svg>
                <h6 className="font-weight-bold mt-3 text-center">
                  Easy Onboarding
                </h6>
                <p className="benefit_subText text-center">
                  Onboarding new employees is easy. Add them to your merk and
                  they have direct communication to the team.
                </p>
              </Col>
              <Col className="d-flex flex-column align-items-center">
                <Lottie
                  options={{
                    loop: true,
                    autoplay: true,
                    animationData: SharedContacts,
                  }}
                  height={100}
                  width={100}
                  style={{ margin: 0, marginBottom: "1rem" }}
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  // xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="60%"
                  height="8px"
                >
                  <path
                    fill-rule="evenodd"
                    stroke="#201cb1"
                    stroke-width="14.04px"
                    stroke-linecap="butt"
                    stroke-linejoin="miter"
                    fill="#201cb1"
                    d="M7.19,16.19 L7.19,7.19 L259.20,7.19 L259.20,16.19 L7.19,16.19 Z"
                  />
                </svg>
                <h6 className="font-weight-bold mt-3 text-center">
                  Shared Contacts
                </h6>
                <p className="benefit_subText text-center">
                  All members of your merk share the same contacts so there is
                  no need to manually add or remove a person.
                </p>
              </Col>
              <Col className="d-flex flex-column align-items-center">
                <Lottie
                  options={{
                    loop: true,
                    autoplay: true,
                    animationData: CreateGroups,
                  }}
                  height={100}
                  width={100}
                  style={{ margin: 0, marginBottom: "1rem" }}
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  // xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="60%"
                  height="8px"
                >
                  <path
                    fill-rule="evenodd"
                    stroke="#201cb1"
                    stroke-width="14.04px"
                    stroke-linecap="butt"
                    stroke-linejoin="miter"
                    fill="#201cb1"
                    d="M7.19,16.19 L7.19,7.19 L259.20,7.19 L259.20,16.19 L7.19,16.19 Z"
                  />
                </svg>
                <h6 className="font-weight-bold mt-3 text-center">
                  Create Groups
                </h6>
                <p className="benefit_subText text-center">
                  Create groups for teams and crews to communicate <br /> and
                  collaborate about projects in real time.
                </p>
              </Col>
              <Col className="d-flex flex-column align-items-center">
                <Lottie
                  options={{
                    loop: true,
                    autoplay: true,
                    animationData: InstantChat,
                  }}
                  height={100}
                  width={100}
                  style={{ margin: 0, marginBottom: "1rem" }}
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  // xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="60%"
                  height="8px"
                >
                  <path
                    fill-rule="evenodd"
                    stroke="#201cb1"
                    stroke-width="14.04px"
                    stroke-linecap="butt"
                    stroke-linejoin="miter"
                    fill="#201cb1"
                    d="M7.19,16.19 L7.19,7.19 L259.20,7.19 L259.20,16.19 L7.19,16.19 Z"
                  />
                </svg>
                <h6 className="font-weight-bold mt-3 text-center">
                  Instant Chat
                </h6>
                <p className="benefit_subText text-center">
                  Communicate with coworkers and colleagues
                  <br />
                  via text messaging, audio call, and video call.
                </p>
              </Col>
              <Col className="d-flex flex-column align-items-center">
                <Lottie
                  options={{
                    loop: true,
                    autoplay: true,
                    animationData: SendFiles,
                  }}
                  height={100}
                  width={100}
                  style={{ margin: 0, marginBottom: "1rem" }}
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  // xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="60%"
                  height="8px"
                >
                  <path
                    fill-rule="evenodd"
                    stroke="#201cb1"
                    stroke-width="14.04px"
                    stroke-linecap="butt"
                    stroke-linejoin="miter"
                    fill="#201cb1"
                    d="M7.19,16.19 L7.19,7.19 L259.20,7.19 L259.20,16.19 L7.19,16.19 Z"
                  />
                </svg>
                <h6 className="font-weight-bold mt-3 text-center">
                  Send Files
                </h6>
                <p className="benefit_subText text-center">
                  Send files such as documents, images, audio, video, and more
                  to team members either individually or in a group.
                </p>
              </Col>
              <Col className="d-flex flex-column align-items-center">
                <Lottie
                  options={{
                    loop: true,
                    autoplay: true,
                    animationData: AnyDevice,
                  }}
                  height={100}
                  width={100}
                  style={{ margin: 0, marginBottom: "1rem" }}
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  // xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="60%"
                  height="8px"
                >
                  <path
                    fill-rule="evenodd"
                    stroke="#201cb1"
                    stroke-width="14.04px"
                    stroke-linecap="butt"
                    stroke-linejoin="miter"
                    fill="#201cb1"
                    d="M7.19,16.19 L7.19,7.19 L259.20,7.19 L259.20,16.19 L7.19,16.19 Z"
                  />
                </svg>
                <h6 className="font-weight-bold mt-3 text-center">
                  Any Device
                </h6>
                <p className="benefit_subText text-center">
                  Merk has mobile apps that function across multiple devices
                  such as Android and iOS smart phones.
                </p>
              </Col>
              <Col className="d-flex flex-column align-items-center">
                <Lottie
                  options={{
                    loop: true,
                    autoplay: true,
                    animationData: WorksGlobally,
                  }}
                  height={100}
                  width={100}
                  style={{ margin: 0, marginBottom: "1rem" }}
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  // xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="60%"
                  height="8px"
                >
                  <path
                    fill-rule="evenodd"
                    stroke="#201cb1"
                    stroke-width="14.04px"
                    stroke-linecap="butt"
                    stroke-linejoin="miter"
                    fill="#201cb1"
                    d="M7.19,16.19 L7.19,7.19 L259.20,7.19 L259.20,16.19 L7.19,16.19 Z"
                  />
                </svg>
                <h6 className="font-weight-bold mt-3 text-center">
                  Works Globally
                </h6>
                <p className="benefit_subText text-center">
                  Merk functions perfectly in every corner of the world using
                  either Wi-Fi technology or cellular network data.
                </p>
              </Col>
              <Col className="d-flex flex-column align-items-center">
                <Lottie
                  options={{
                    loop: true,
                    autoplay: true,
                    animationData: SecureEncryption,
                  }}
                  height={100}
                  width={100}
                  style={{ margin: 0, marginBottom: "1rem" }}
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  // xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="60%"
                  height="8px"
                >
                  <path
                    fill-rule="evenodd"
                    stroke="#201cb1"
                    stroke-width="14.04px"
                    stroke-linecap="butt"
                    stroke-linejoin="miter"
                    fill="#201cb1"
                    d="M7.19,16.19 L7.19,7.19 L259.20,7.19 L259.20,16.19 L7.19,16.19 Z"
                  />
                </svg>
                <h6 className="font-weight-bold mt-3 text-center">
                  Secure Encryption
                </h6>
                <p className="benefit_subText text-center">
                  Merk guarantees end-to-end encryption for secure chat. We
                  can't read your messages or listen to your calls.
                </p>
              </Col>
            </Row>
          </div>
          <div className="bg-white text-center cards">
            {/* <h1 className="font-family-2 pt-0" style={{ fontSize: '2.5rem' }}>
              The #1 App For Construction Communication
            </h1> */}
            <Row sm="1" xs="1" md="2" lg="3" className="row3">
              <Col className="p-2">
                <Card>
                  <CardImg top width="100%" src={simple} alt="Card image cap" />
                  <CardBody className="card1">
                    <CardTitle>
                      <h2>
                        <strong>Simple</strong>
                      </h2>
                    </CardTitle>
                    <CardText>
                      Merk Mobile was designed
                      <br />
                      with simplicity in mind.
                    </CardText>
                  </CardBody>
                </Card>
              </Col>
              <Col className="p-2">
                <Card>
                  <CardImg top width="100%" src={secure} alt="Card image cap" />
                  <CardBody className="card2">
                    <CardTitle>
                      <h2>
                        <strong>Secure</strong>
                      </h2>
                    </CardTitle>
                    <CardText>
                      We can’t read your messages <br />
                      or listen to your calls.{" "}
                    </CardText>
                  </CardBody>
                </Card>
              </Col>
              <Col className="p-2">
                <Card>
                  <CardImg
                    top
                    width="100%"
                    src={scalable}
                    alt="Card image cap"
                  />
                  <CardBody className="card3">
                    <CardTitle>
                      <h2>
                        <strong>Scalable</strong>
                      </h2>
                    </CardTitle>
                    <CardText>
                      Scale from 10 users to 10,000 users with no problem.{" "}
                    </CardText>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </>
    );
  }
}
