import React, { Component, createRef } from "react";
import { Link, Redirect } from "react-router-dom";
import { Button, FormGroup, Form, Input, Row, Col } from "reactstrap";
import { connect } from "react-redux";
import { register } from "../../Redux/Actions/authAction";
import { Spinner } from "reactstrap";
import email from "../../assets/img/dashboard-Slicing/Email.svg";
import Company from "../../assets/img/dashboard-Slicing/Company Name.svg";
import password from "../../assets/img/dashboard-Slicing/Password.svg";
import logo from "../../assets/img/Landing page-slicing/mark-text-logo.png";
import camlogo from "../../assets/img/dashboard-Slicing/camera.svg";
import Lottie from "react-lottie";
import Navbar from "../../components/global/Navbar";
import loginLottie from "../../assets/img/lotties/auth.json";

export class Register extends Component {
  image = createRef(null);
  constructor(props) {
    super(props);
    console.log(this.props);
    this.state = {
      name: "",
      email: "",
      password: "",
      Confirmpassword: "",
      ref:
        window.location.href.split("?").length > 1
          ? window.location.href.split("?")[1].replace("refcode=", "")
          : "",
      verificationDetail: null,
      // file: "",
    };
  }

  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  componentDidMount() {
    const verificationDetailJSON = localStorage.getItem("verification_detail");
    const verificationDetail = JSON.parse(verificationDetailJSON);
    this.setState({ verificationDetail });
  }
  render() {
    const { register, loading } = this.props;
    console.log("register", register);
    console.log(this.state.verificationDetail);
    if (register) return <Redirect to="/auth/login" />;
    return (
      <>
        <Row style={{ height: "calc(100vh - 80px)", marginTop: "5rem" }}>
          <div
            className="position-absolute w-100"
            style={{ top: "0px", left: "0px" }}
          >
            <Navbar />
          </div>
          <Col
            lg="7"
            md="7"
            className="d-flex justify-content-center align-items-center"
          >
            <div style={{ width: "70%" }}>
              <div className="text-center mb-1">
                {/* <img src={logo} width={100} alt="icon" /> */}
                <h3 className="mt-3 mb-4 lgn-heading">Register to Merk Mobile</h3>
                {this.state.verificationDetail ? (
                  <div className="bg-site-primary1 mb-3 shadow-sm d-flex align-items-center justify-content-center text-secondary">
                    <p className="mb-0">
                      You're signing up with a charity bearing EIN{" "}
                      {this.state.verificationDetail?.ein}
                    </p>
                  </div>
                ) : (
                  ""
                )}

                {/* <div className="d-flex justify-content-center m-auto p-2">
                  <div
                    className="bg-site-primary d-flex p-0 align-items-center justify-content-center rounded-circle shadow"
                    onClick={(e) => this.image.current.click()}
                    style={{
                      border: "7px solid white",
                      width: 125,
                      height: 125,
                      cursor: "pointer",
                      overflow: "hidden",
                    }}
                  >
                    <img
                      className={this.state.file != "" ? "h-100 w-100" : ""}
                      src={
                        this.state.file == ""
                          ? camlogo
                          : URL.createObjectURL(this.state.file)
                      }
                      style={
                        this.state.file != "" ? { objectFit: "cover" } : {}
                      }
                      alt="icon"
                    />
                  </div>
                  <input
                    className="d-none"
                    type="file"
                    ref={this.image}
                    onChange={(e) => this.setState({ file: e.target.files[0] })}
                  ></input>
                </div> */}
              </div>
              <Form
                role="form"
                onSubmit={(e) => {
                  e.preventDefault();

                  let password = this.state.password;
                  let Confirmpassword = this.state.Confirmpassword;
                  if (password != Confirmpassword) {
                    alert("Password didn't matched, Password should be same.");
                  } else {
                    let creds;
                    creds = {
                      name: this.state.name,
                      email: this.state.email,
                      password,
                      friendRef: this.state.ref,
                      // image: this.state.file,
                    };
                    console.log("creds", creds);
                    this.props.authRegister(creds);
                  }
                }}
              >
                <FormGroup className="mb-3">
                  <div className="position-relative form-box">
                    <div className="position-absolute form-icon" left="10">
                      <img src={Company} alt="icon" width="16px" />
                    </div>
                    <Input
                      placeholder="Company Name"
                      name="name"
                      type="name"
                      onChange={this.handleInput}
                      required
                      className="pl-5 py-4 shadow-sm border-0"
                    />
                  </div>
                </FormGroup>
                <FormGroup className="mb-3">
                  <div className="position-relative form-box">
                    <div className="position-absolute form-icon" left="10">
                      <img src={email} alt="icon" width="16px" />
                    </div>
                    <Input
                      placeholder="Email"
                      name="email"
                      type="email"
                      onChange={this.handleInput}
                      required
                      autoComplete="new-email"
                      className="pl-5 py-4 shadow-sm border-0"
                    />
                  </div>
                </FormGroup>
                <FormGroup>
                  <div className="position-relative form-box">
                    <div className="position-absolute form-icon" left="10">
                      <img src={password} alt="icon" width="14px" />
                    </div>
                    <Input
                      placeholder="Password"
                      name="password"
                      type="password"
                      onChange={this.handleInput}
                      required
                      className="pl-5 py-4 shadow-sm border-0"
                    />
                  </div>
                </FormGroup>
                <FormGroup>
                  <div className="position-relative form-box">
                    <div className="position-absolute form-icon" left="10">
                      <img src={password} alt="icon" width="14px" />
                    </div>
                    <Input
                      placeholder="Confirm Password"
                      name="Confirmpassword"
                      type="password"
                      onChange={this.handleInput}
                      required
                      className="pl-5 py-4 shadow-sm border-0"
                    />
                  </div>
                </FormGroup>
                <FormGroup>
                  <div className="position-relative form-box">
                    <div className="position-absolute form-icon" left="10">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="16px"
                        viewBox="0 0 576 512"
                        fill="#8fa2bb"
                      >
                        <path d="M64 64C28.7 64 0 92.7 0 128v64c0 8.8 7.4 15.7 15.7 18.6C34.5 217.1 48 235 48 256s-13.5 38.9-32.3 45.4C7.4 304.3 0 311.2 0 320v64c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V320c0-8.8-7.4-15.7-15.7-18.6C541.5 294.9 528 277 528 256s13.5-38.9 32.3-45.4c8.3-2.9 15.7-9.8 15.7-18.6V128c0-35.3-28.7-64-64-64H64zm64 112l0 160c0 8.8 7.2 16 16 16H432c8.8 0 16-7.2 16-16V176c0-8.8-7.2-16-16-16H144c-8.8 0-16 7.2-16 16zM96 160c0-17.7 14.3-32 32-32H448c17.7 0 32 14.3 32 32V352c0 17.7-14.3 32-32 32H128c-17.7 0-32-14.3-32-32V160z" />
                      </svg>
                      {/* <img src={password} alt="icon" width="14px" /> */}
                    </div>
                    <Input
                      defaultValue={this.state.ref}
                      placeholder="Referral code"
                      name="friendRef"
                      onChange={this.handleInput}
                      required
                      className="pl-5 py-4 shadow-sm border-0"
                    />
                  </div>
                </FormGroup>
                <div className="text-center">
                  <Button
                    className="mb-3 w-100 bg-site-primary shadow register-btn"
                    type="submit"
                  >
                    {loading ? <Spinner size="sm" /> : "Sign Up"}
                  </Button>
                </div>
              </Form>

              <div className="text-center mb-2">
                Already have an account?
                <Link className="text-site-primary ml-1" to="/auth/login">
                  Sign In
                </Link>
              </div>
            </div>
          </Col>
          <Col
            lg="5"
            md="5"
            className="auth-bg d-flex justify-content-center align-items-center py-5"
          >
            <div>
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: loginLottie,
                }}
                height={300}
              />
              <h1 className=" text-white text-center mb-3">
                All of your team. <br /> All in one place.
              </h1>
              <h6 className="text-white text-center">
                The #1 App For Mobile Team Communication
              </h6>
            </div>
          </Col>
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.auth.requested,
    register: state.auth.registered,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    authRegister: (creds) => dispatch(register(creds)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Register);
